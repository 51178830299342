.main_right_area{
	.text-list-area{
        .text-list{
            padding: 8px 0;
            position: relative;
            @media screen and (min-width: $screen_md_width){
            	padding: 14px 0;
            }
            background: url('../img/index/dotted_line_2x.png') repeat-x left bottom;
            background-size: 6px 1px;
            padding-right: 10px;
        	
        	&.bottom-space{
        		padding-bottom: 60px;
        		@media screen and (min-width: $screen_md_width){
	            	padding-bottom: 14px;
	            }
        	}
	        .date-title-area{
	            @extend %clearfloat;
	            position: relative;
	            display: inline-block;
	            vertical-align: top;

	            @media screen and (min-width: $screen_md_width){
	                .date{
	                    float: left;
	                }
	                .title{
	                    padding-left: 98px;
	                    display: block;
	                    font-size: 0;
	                    span{
	                    	font-size: 15px;
	                        display: inline-block;
	                        overflow : hidden;
	                        text-overflow: ellipsis;
	                        white-space: nowrap;
	                        vertical-align: middle;
	                        max-width: 582px;
	                        &.is_download{
	                        	max-width: 550px;
	                        }
	                        &.tag-cnt-1{
	                            max-width: 520px;
	                        }
	                        &.tag-cnt-2{
	                            max-width: 476px;
	                        }
	                    }
	                    

	                }
	            }
	            @media screen and (min-width: $screen_lg_width){
	                .title{
	                    span{
	                        max-width: 776px;
	                         &.is_download{
	                        	max-width: 740px;
	                        }
	                        &.tag-cnt-1{
	                            max-width: 720px;
	                        }
	                        &.tag-cnt-2{
	                            max-width: 676px;
	                        }
	                    }
	                }
	                
	            }
	            .news-tag{
	                width: 44px;
	                height: auto;
	                margin-left: 3px;
	            }
	        }
			.download-area{
				font-size: 15px;
				display: block;
				position: absolute;
				bottom: 20px;
				right: 0;
				padding: 4px 20px;
				border-radius: 5px;
				background-color: #f3f3f3;
				color: map-get($website_main_color, '3');
				@media screen and (min-width: $screen_md_width){
					font-size: 0;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					background: url('../img/page/icon_download_2x.png');
					background-size: 100%;
					padding: 0;
					width: 24px;
					height: 22px;
					
					&:hover{
					background: url('../img/page/icon_download_hover_2x.png');
					background-size: 100%;
					}

				}

			}
			&.type-2{ //學術刊物
				position: relative;
				padding-right: 10px;
				@media screen and (min-width: $screen_md_width){
					padding-right: 65px;
				}
				.sn-area{
					color: map-get($website_main_color, '3');
					font-size: 15px;
				}
				.date{
					color: map-get($website_sub_color, '6');
					font-size: 15px;
					margin-left: 10px;
					padding-top: 0;
				}
				.title{
				    font-size: 15px;
					color: map-get($website_main_color, '2');
				}
				.date-title-area{
					&:hover{
						.date{
							color: #109a88;

						}
						.title{
							color: #0b9c89;
						} 
					}
				}
				
				.description{
					color: map-get($website_sub_color, '9');
					font-size: 14px;
					padding-bottom: 65px;
					@media screen and (min-width: $screen_md_width){
						padding-bottom: 0;
						padding-left: 74px;
					}
				}
				// .download-area{
				// 	font-size: 15px;
				// 	display: block;
				// 	position: absolute;
				// 	bottom: 20px;
				// 	right: 0;
				// 	padding: 4px 20px;
				// 	border-radius: 5px;
				// 	background-color: #f3f3f3;
				// 	color: map-get($website_main_color, '3');
				// 	@media screen and (min-width: $screen_md_width){
				// 		font-size: 0;
				// 		top: 50%;
				// 		right: 0;
				// 		transform: translateY(-50%);
				// 		background: url('../img/page/icon_download_2x.png');
				// 		background-size: 100%;
				// 		padding: 0;
				// 		width: 24px;
				// 		height: 22px;
						
				// 		&:hover{
				// 		background: url('../img/page/icon_download_hover_2x.png');
				// 		background-size: 100%;
				// 		}

				// 	}

				// }
			}
			&.type-3{ //會員繳款資訊 教育課程查詢
				@extend %clearfloat;
				font-size: 15px;
				color: map-get($website_sub_color, '1');
				padding-right: 0;
				padding-left: 0;
				position: relative;
				.info-key{
					@media screen and (min-width: 600px){
						display: none;
					}
				}
				.info-value{
					&.date{
						color: map-get($website_sub_color, '6');
					}
				}
				.info-list {
					margin-bottom: 3px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				.lesson-info-title{
					font-size: 15px;
					color: map-get($website_sub_color, '1');
					&:hover{
						color: #0b9c89;
					}
				}
				.info-list.report{
					a{
						font-size: 15px;
						color: map-get($website_sub_color, '3');
						&:hover{
							color: map-get($website_main_color, '3');
						}
					}
					display: none;
					@media screen and (min-width: 600px){
						display: table-cell;
					}
				}
				.report-btn{
					display: block;
					position: absolute;
					right: 0;
					bottom: 20px;
					padding: 5px 20px;
					color: map-get($website_main_color, '3');
					background-color: map-get($website_main_color, '5');
					@media screen and (min-width: 600px){
						display: none;
					}
				}
				@media screen and (min-width: 600px){
					display: table;
					width: 100%;
					.info-list{
						display: table-cell;
						padding: 2px 20px;
						&.year{
							width: 145px;
						}
						&.date,&.status, &.report{
							width: 120px;
						}
						&.report{
							padding-left: 34px;
						}
					}
				}
			}
			&.type-4{ //會員福利列表
				@extend %clearfloat;
				@media screen and (min-width: $screen_md_width){
					display: table;
					width: 100%;
					.left-area{
						display: table-cell;
					}
					.right-area{
						display: table-cell;
						width: 104px;
					}
					.title{
						padding-left: 186px;
						span{
							max-width: 380px;
							&.tag-cnt-1{
								max-width: 312px;
							}
							&.tag-cnt-2{
								max-width: 268px;
							}
						}
					}
				}
				@media screen and (min-width: $screen_lg_width){    
					.right-area{
						width: 135px;
					}
					.title{
						span{
							max-width: 538px;
							&.tag-cnt-1{
								max-width: 480px;
							}
							&.tag-cnt-2{
								max-width: 434px;
							}
						}
					}
				}
				
				.welfare-type{
					display: block;
					font-size: 15px;
					color: map-get($website_main_color, '3');
					max-width: 96px;
				}
			}

        }
        .heading{
			display: none;
			font-size: 15px;
			color: map-get($website_sub_color, '1');
			@media screen and (min-width: 600px){
				display: table;
				width: 100%;
				background-color: map-get($website_main_color, '5');
				.heading-title{
					display: table-cell;
					padding: 11px 20px;
					// border: 1px solid red;
					&.year{
						width: 145px;
					}
					&.date, &.status, &.report{
						width: 120px;
					}


				}
			}
		}
    }
	.media-list-area{
		.media-list{
			@extend %clearfloat;
			padding: 22px 0;
			background: url('../img/index/dotted_line_2x.png') repeat-x left bottom;
			background-size: 6px 1px;
			padding-right: 10px;
			.media-area{
	            border-radius: 10px;
	            overflow: hidden;
	            border: 1px solid #DDD;
	            // max-width: 259px;
	            width: 100%;
	            margin: 0 auto;
	            .media-box{
	                display: block;
	                height: 0;
	                padding-bottom: 36.97%;
	                position: relative;
	                img{
	                    position: absolute;
	                    left: 0;
	                    top: 0;
	                    width: 100%;
	                    height: 100%;
	                }
	            }
	            @media screen and (min-width: 500px){
	            	float: left;
	            	width: 230px;
	            }
	            @media screen and (min-width: $screen_md_width){
	            	width: 259px;
	            }
			}
			.content-area{
				margin-top: 10px;
				.title{
					display: inline-block;
					color: map-get($website_main_color, '2');
					font-size: 15px;
					&:hover{
						color: #0b9c89;
					}
				}
				.description{
					color: map-get($website_sub_color, '9');
					font-size: 14px;
				}
				@media screen and (min-width: 500px){
					margin-top: 0;
					padding-left: 260px;
					.title{
						margin-top: -4px;
					}
				}
			 	@media screen and (min-width: $screen_md_width){
	            	padding-left: 289px;
	            }
			}
		}
	}
}