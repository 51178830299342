@mixin border-box($width,$type,$color,$background-color,$radius,$padding) {
  border: $width $type $color;
  border-radius: $radius;
  padding: $padding;
  background: $background-color;
  img {
  	max-width: 100%;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}


@mixin two-column-system($first_column_width){
  display:table;
  width: 100%;
  >.column{
    display: table-cell;
    vertical-align: top;
    width: calc(100% - #{$first_column_width});
    &.static-column{
      width: #{$first_column_width};
    }
  }
  
}

@mixin multi-column-system($column_setting_config){
  $column_num: length($column_setting_config);
  display:table;
  table-layout: fixed;
  width: 100%;
  >.column{
    display: table-cell;
    vertical-align: top;
    @for $i from 1 through length($column_setting_config) {
      &:nth-child(#{$i}){
        width: nth($column_setting_config,$i);
      }
    }
  }
}




@mixin acart-row-col($padding,$grid_num){
  margin-left: -#{$padding / 2};
  margin-right: -#{$padding / 2};
  >.acart-col{
    float: left;
    width: #{100% / $grid_num};
    padding:  0 #{$padding / 2} ;
  }
}


@mixin css-triangle($direction, $color, $height, $width) {
    // Base styles for all triangles
    border-style: solid;
    height: 0;
    width: 0;
    // Cardinal Directions - can't be scalene this way
    @if($direction == 'top') {
        border-color: transparent transparent $color transparent;
        border-width: 0 ($width/2) $height ($width/2);
    }
    @if ($direction == 'bottom') {
        border-color: $color transparent transparent transparent;
        border-width: $height ($width/2) 0 ($width/2);
    }
    @if ($direction == 'right') {
        border-color: transparent transparent transparent $color;
        border-width: ($height/2) 0 ($height/2) $width;
    }
    @if ($direction == 'left') {
        border-color: transparent $color transparent transparent;
        border-width: ($height/2) $width ($height/2) 0;
    }
    // Ordinal Directions - can be scalene this way!
    @if ($direction == 'top-left') {
        border-color: $color transparent transparent transparent;
        border-width: $height $width 0 0;
    }
    @if ($direction == 'top-right') {
        border-color: transparent $color transparent transparent;
        border-width: 0 $width $height 0;
    }
    @if ($direction == 'bottom-left') {
        border-color: transparent transparent transparent $color;
        border-width: $height 0 0 $width;
    }
    @if ($direction == 'bottom-right') {
        border-color: transparent transparent $color transparent;
        border-width: 0 0 $height $width;
    }
}


%title-left-icon {
	padding-left: 15px;
    background: url(../img/index/list_icon.png) 0px 50% no-repeat;
    background-size: 7px 9px;
    &:hover {
    	background: url(../img/index/list_icon_hover.png) 0px 50% no-repeat;
    	background-size: 7px 9px; 
    }
}

.date-title-area{
  display: block;
  .date{
    color: map-get($website_sub_color, '6');
    font-size: 15px;
  }
  .title{
    font-size: 15px;
    color: map-get($website_main_color, '2');
  }

  &:hover{
    .date{
      color: #109a88;

    }
    .title{
      color: #0b9c89;
    } 
  }

}

%clearfloat {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.pagination{
  display: block;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0px;
  
  @media screen and (min-width: $screen_md_width){
    margin-top: 66px;
  }
  ul{
    @extend %clearfloat;
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;
    vertical-align: top;
    li{
      float: left;
      display: block;
      font-size: 13px;
      margin: 0 8px; 
      >a, >span{
        color: map-get($website_main_color,'2');
        &:hover, &.selected{
          color: map-get($website_sub_color,'7');
          text-decoration: underline;
        }
      }
      &.previous-page{
        margin-right: 16px;
        margin-left: 0;
      }
      &.next-page{
        margin-right: 0;
        margin-left: 16px;
      }
      i{
        font-size: 14px;
        padding-top: 3px;
      }
    }
  }
}
h1.index-h1{
  margin: 0;
  font-size: 0;
}
%edit-area {
  font-size: 15px;
	iframe{
      max-width: 100%;
      border: 0;
  }
  img{
      height: auto;
      max-width: 100%;
  }

  h2{
      // display: block;
      // color:  #000000;
      // font-size: 18px;
      // font-weight: bold;
      // position: relative;
  }
  h3{
      font-size: 17px;
      color: map-get($website_main_color,'4');
  }
  h4{
      font-size: 15px;
      color: map-get($website_main_color,'3');
  }
  p{
      color: map-get($website_main_color,'2');
      font-size: 15px;
  }
  a{
    text-decoration: underline;
    color: map-get($website_main_color,'2');
    &:hover{
      color: map-get($website_main_color,'3');
    }
  }

  ul{
      padding: 0;
      list-style: none;
      color: map-get($website_sub_color,'1');
      li{
          padding-left: 16px;
          position: relative;
          &:before{
              content: '';
              display: block;
              position: absolute;
              left: 2px;
              top: 8px;
              @include css-triangle(right, map-get($website_main_color,'3'), 10px, 6px);
          }
      }
  }

  ol{
      padding: 0;
      list-style: none;
      counter-reset: ol-item;
      color: map-get($website_main_color,'2');
      li{
          display: block;
          padding-left: 16px;
          position: relative;
          &:before{
              position: absolute;
              text-align: right;
              left: -10px;
              top: 0;
              content: counter(ol-item)"."; 
              counter-increment: ol-item;
              font-size: 15px;
              color: map-get($website_main_color,'2');
              display: inline-block;
              min-width: 22px;
          }

      }
  }

  table{
      max-width:100%;
      border-collapse: collapse;
      border-style: solid;
      border: 1px solid map-get($website_sub_color,'3');
      tr{
        th, td{
          font-size: 15px;
          border: 1px solid map-get($website_sub_color,'3');
          padding: 5px 14px;
        }
      }
      th{
        color: map-get($website_main_color,'3');
        background-color: map-get($website_sub_color,'5');
      }
      td{
        color: map-get($website_main_color,'2');
      }
  }
}
	
@mixin border-box($width,$type,$color,$background-color,$radius,$padding) {
  img {
    max-width: 100%;
  }    
  @if $type == 'none' {
    border: 0;
    border-radius: 'none';
    padding: $padding ;
    background: $background-color;
  }
  @else if $type != 'none' {
    border: $width $type $color;
    border-radius: $radius;
    padding: $padding;
    background: $background-color;
  }
}


@mixin text-box-end-hidden($width:50px,$height:20px,$rgba_color:rgba(255, 255, 255, 0),$color:#fff) {
    &:after {
        position: absolute;
        display: block;
        content: '';
        bottom: 0px;
        right: 0px;
        width: $width;
        height: $height;
        background: -webkit-linear-gradient(left, $rgba_color, $color);
        background: -o-linear-gradient(left, $rgba_color, $color);
        background: -moz-linear-gradient(left, $rgba_color, $color);
        background: linear-gradient(left, $rgba_color, $color);
    } 
}

@mixin text-box-end-inline($width:50px,$height:20px,$content_text:'',$rgba_color:rgba(255, 255, 255, 0),$color:#fff) {
    &:after {
        display: inline-block;
        content: $content_text;
        bottom: 0px;
        right: 0px;
        width: $width;
        height: $height;
        background: -webkit-linear-gradient(left, $rgba_color, $color);
        background: -o-linear-gradient(left, $rgba_color, $color);
        background: -moz-linear-gradient(left, $rgba_color, $color);
        background: linear-gradient(left, $rgba_color, $color);
    } 
}

// @mixin base-list-box($box_top_margin:20px,$has_float:true,$float_position:left,$default_float_box_width:0,$content_padding:0,$lg_float_box_width:0,$md_float_box_width:0,$sm_float_box_width:0,$xs_float_box_width:0) {
// margin-top:$box_top_margin; 
//   @if $has_float == true {
//     &:after {
//         content: ".";
//         display: block;
//         height: 0;
//         clear: both;
//         visibility: hidden;
//     }
//     @if $float_position == 'none' {
      
//     }
//     @else if $float_position != 'none' {
//       // .media-area { 
//       //   float: $media_position ; 
//       // }
//       @if $default_float_box_width != 0 {
//         .content-area {
//           margin-#{$float_position}: $default_float_box_width + $content_padding;  
//         }
//       }
//       @if $xs_float_box_width != 0 {
//         @media screen and (min-width: $screen_xs_width) {
//           .content-area {
//             margin-#{$float_position}: $xs_float_box_width + $content_padding; 
//           }       
//         }
//       }
//       @if $sm_float_box_width != 0 {      
//         @media screen and (min-width: $screen_sm_width) {
//           .content-area {
//             margin-#{$float_position}: $sm_float_box_width + $content_padding; 
//           }       
//         }
//       }
//       @if $md_float_box_width != 0 {  
//         @media screen and (min-width: $screen_md_width) {
//           .content-area {
//             margin-#{$float_position}: $md_float_box_width + $content_padding; 
//           }       
//         }
//       }
//       @if $lg_float_box_width != '0' {        
//         @media screen and (min-width: $screen_lg_width) { 
//           .content-area {
//             margin-#{$float_position}: $lg_float_box_width + $content_padding; 
//           }       
//         }
//       }          
//     }
//   }
//   // @else if $has_media == false {
//   //     .media-area {
//   //       display:none;
//   //     }
//   // }  
// }
@mixin float-base-list-box($box_top_margin:20px,$has_float:true,$float_position:left,$float_area_name:'media',$default_float_box_width:0,$content_padding:0,$lg_float_box_width:0,$md_float_box_width:0,$sm_float_box_width:0,$xs_float_box_width:0,$xss_float_box_width:0){
  margin-top:$box_top_margin;
  .#{$float_area_name}-area {
	  float: none ; 
      width: $default_float_box_width; 
	}
  @if $has_float == true {
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    @if $float_position == 'none' {
      
    }
    @else if $float_position != 'none' {
      // .#{$float_area_name}-area {
      //   float: #{$float_position} ; 
      // }
      @if $default_float_box_width != 0 {
        .#{$float_area_name}-area {
          float: #{$float_position} ; 
          width: $default_float_box_width; 
        }
        .content-area {
          margin-#{$float_position}: $default_float_box_width + $content_padding;  
        }
      }
      @else if $default_float_box_width == 0 {
        .#{$float_area_name}-area {
          width: 100%; 
          margin-bottom:5px;
        }
      }
      @if $xss_float_box_width != 0 {
        @media screen and (min-width: $screen_xs_width) {
          @if $float_area_name == 'media' {
            .media-area {
              float: #{$float_position} ; 
              width: $xss_float_box_width; 
            } 
          }       
          .content-area {
            margin-#{$float_position}: $xss_float_box_width + $content_padding; 
          }       
        }
      }
      @else if $xss_float_box_width == 0 {
        .#{$float_area_name}-area {
          width: 100%; 
          margin-bottom:5px;
        }
      }      
      @if $xs_float_box_width != 0 {
        @media screen and (min-width: $screen_xs_width) {
          @if $float_area_name == 'media' {
            .media-area {
              float: #{$float_position} ; 
              width: $xs_float_box_width; 
            } 
          }       
          .content-area {
            margin-#{$float_position}: $xs_float_box_width + $content_padding; 
          }       
        }
      }
      @else if $xs_float_box_width == 0 {
        .#{$float_area_name}-area {
          width: 100%; 
          margin-bottom:5px;
        }
      }
      @if $sm_float_box_width != 0 {    
        @media screen and (min-width: $screen_sm_width) {
          @if $float_area_name == 'media' {
            .media-area {
              float: #{$float_position} ; 
              width: $sm_float_box_width; 
            } 
          }       
          .content-area {
            margin-#{$float_position}: $sm_float_box_width + $content_padding; 
          }       
        }
      }
      @if $md_float_box_width != 0 {  
        @media screen and (min-width: $screen_md_width) {
          @if $float_area_name == 'media' {
            .media-area {
              float: #{$float_position} ; 
              width: $md_float_box_width; 
            } 
          }          
          .content-area {
            margin-#{$float_position}: $md_float_box_width + ($content_padding*2); 
          }       
        }
      }
      @if $lg_float_box_width != '0' {        
        @media screen and (min-width: $screen_lg_width) { 
          @if $float_area_name == 'media' {
            .media-area {
              float: #{$float_position} ; 
              width: $lg_float_box_width; 
            } 
          }         
          .content-area {
            margin-#{$float_position}: $lg_float_box_width + ($content_padding*2); 
          }       
        }
      }          
    }
  }
}

%title-left-icon {
	  padding-left: 15px;
    background: url(../img/index/list_icon.png) 0px 50% no-repeat;
    background-size: 7px 9px;
    &:hover {
    	background: url(../img/index/list_icon_hover.png) 0px 50% no-repeat;
    	background-size: 7px 9px;  
    }
}

.breadcrumb-area{
  text-align: right;
}
.breadcrumbs { 
  font-size: 14px;
  margin: 8px 0px 10px 0px;
  text-align: left;
  display: inline-block;
  @media screen and (min-width: $screen_md_width){
    margin: 12px 0px 12px 0px;
  }
  a{
    color: map-get($website_main_color,'1');
  }
  span{
    color: map-get($website_sub_color,'7');
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 224px;
    // display: inline-block;
    // vertical-align: middle;
  }
}

.page_list{
  text-align: center;
  margin: 65px 0 0px 0; 
  a{
    display: inline-block;
    color: map-get($website_main_color,'2');
    font-size: 13px;
    &.hover, &:hover{
      color: map-get($website_sub_color,'7');
      text-decoration: underline;
    }
  }

}
.tag{
  display: inline-block;
  height: 22px;
  min-height: 22px;
  line-height: 22px;
  border-radius: 22px;
  background: map-get($website_main_color, '6');
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  margin-right: 4px;
  padding: 0 19px;
  vertical-align: middle;
  &.static-tag{
    width: 80px;
    padding: 0;
    &.two-word{
      letter-spacing: 10px;
      padding-left: 12px;
    }
  }
  
}
#to-top{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 50px;
  background-color: map-get($website_sub_color, '3');
  cursor: pointer;
  z-index: 3;
  text-align: center;
  display: none;
  position: fixed;
  right: 28px;
  bottom: 10px;


  &.bot{
    position: absolute;
    width: 100px;
    height: 75px;
    top: -33px;
    
    @media screen and (min-width: $screen_md_width){
      width: 50px;
      height: 50px;
      top: -70px;
    }

  }
  &:hover{
    background-color: map-get($website_sub_color, '3');
  }
  @media screen and (min-width: $screen_md_width){
    // background-image: url('../img/index/icon_top.png');
    // background-size: 50px auto;
    background-color: #a24f63;
    font-size: 12px;
    right: calc(50% - #{$container_md}/2);
  }
  @media screen and (min-width: $screen_lg_width){
    right: calc(50% - #{$container_lg}/2);
  }
  @media screen and (min-width: 1550px){
    right: calc(50% - #{$container_lg}/2 - 152px);
  }
}
.modal-dialog {
  position: absolute;
  margin: 0;
  padding: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}