.acart-proportion-box {
    height: 0;
    position: relative;

    > .proportion-item {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
    }

    >iframe {
    	&.proportion-item{
	        width: 100%;
	        height: 100%;
    	}
    }
    
    &.proportion-3-4 {
        padding-bottom: #{(4 / 3) * 100%};
    }
    &.proportion-4-3 {
        padding-bottom: #{(3 / 4) * 100%};
    }

    &.proportion-16-9 {
        padding-bottom: #{(9 / 16) * 100%};
    }

    &.proportion-5-4{
        padding-bottom: #{(4 / 5) * 100%};
    }
    &.proportion-4-5{
        padding-bottom: #{(5 / 4) * 100%};
    }
    &.proportion-1-1{
        padding-bottom: 100%;
    }
}