$xs_header_height:  55px;
$sidebar_main_color: map-get($website_sub_color,'5');
$sidebar_layer_config: (
	'1' : (
		'font-size': 16px,
		'color': map-get($website_main_color,'1'),
		'hover_color': map-get($website_main_color,'1'),
		'background': map-get($website_sub_color,'5'),
		'hover_background': map-get($website_sub_color,'5'),
		'icon_color': map-get($website_sub_color, '3'),
		'icon_hover_color': map-get($website_sub_color, '3'),
		'line_color': #bcdeee, 
		'line_width': 1px, 
	),
	'2' : (
		'font-size': 16px,
		'color': map-get($website_main_color,'1'),
		'hover_color':map-get($website_main_color,'1') ,
		'background': #aedef1,
		'hover_background': #aedef1,
		'icon_color': map-get($website_sub_color, '3'),
		'icon_hover_color': map-get($website_sub_color, '3'),
		'line_color': #9cd1ea, 
		'line_width': 1px, 
	),
	'3' : (
		'font-size': 16px,
		'color': map-get($website_main_color,'1'),
		'hover_color': map-get($website_main_color,'1'),
		'background': #8bd1ed,
		'hover_background': #8bd1ed,
		'icon_color': #FFF,
		'icon_hover_color': #FFF,
		'line_color': #7bc4e6, 
		'line_width': 1px, 
	)

); 

div#mb-sidebar {
	display: none;
	position: fixed; 
	width: 100%; 
	top: -100%; 
	z-index: 20; 
	background: map-get($website_sub_color,'5');
   	.slimScrollDiv{
   		width: 100%;
   	}
	&.hidden-sidebar {
		display: block;
		top: -100%; 
		-webkit-animation-name: hiddensidebar; 
	    -webkit-animation-timing-function: ease-in;
	    -webkit-animation-duration: 0.5s; 
	    animation-name: hiddensidebar;
	    animation-timing-function: ease-in;
	    animation-duration: 0.5s;
	}
	&.show-sidebar {
		display: block;
		top: $xs_header_height;
		height: 110vh;
		-webkit-animation-name: showsidebar; 
	    -webkit-animation-timing-function: ease-in;
	    -webkit-animation-duration: 0.5s; 
	    animation-name: showsidebar;
	    animation-timing-function: ease-in;
	    animation-duration: 0.5s;
	}
	@media screen and (min-width: $screen_md_width) {
		display: none;
		&.show-sidebar {
			display: none;
		}
	}
	.sidebar-top{
		&.english{
			.dropdown{
				width: 100%;
			}
			.share-box{
				display: none;
			}
		}
	}
}



@include keyframes(hiddensidebar){
	0% { top:$xs_header_height; }
    100% { top:-100%; }
}
@include keyframes(showsidebar){
	0% { top:-100%; }
    100% { top:$xs_header_height; }
}

div.sidebar-content { 
	padding-bottom: $xs_header_height;
	.btn-area{
		@extend %clearfloat;
		text-align: center;
		.sidebar-btn{
			width: 50%;
			position: relative;
			display: inline-block;
			padding: 9px 10px 7px 10px;
			color: #FFF;
			float: left;
			span{
				display: inline-block;
				position: relative;
				top: 1px;
				padding-left: 28px;
			}
			&.ch{
				background-color: map-get($website_sub_color,'7');
				span{
					background: url('../img/sidebar/menu_icon_ch_2x.png') left top 2px no-repeat;
					background-size: 25px 21px;
				}
			}
			&.en{
				background-color: map-get($website_sub_color,'7');
				span{
					background: url('../img/sidebar/menu_icon_en_2x.png') left top 2px no-repeat;
					background-size: 25px 21px;
				}
			}
			&.member{
				border-left: 1px solid rgba(255, 255, 255, 0.5);
				background-color: map-get($website_main_color,'3');	
				span{
					background: url('../img/sidebar/menu_icon_member_2x.png') left top 2px no-repeat;
					background-size: 25px 21px;
				}
			}
		}
	}
	.search-area{
		padding: 10px 10px;
		.input-group{
			width: 100%;
			height: 35px;
			border: 0;
			input{
				padding:0 50px 0 16px;
				font-size: 14px;
				width: 100%;
				border-radius: 5px;
				border: 0;
				
			}
			@include placeholder {
				color: #c2c2c2;
				font-size: 14px;
			}



			.btn{
				width: 50px;
			}
			input,.btn{
				height: 100%;
			}
			.btn{
				color: transparent;
				overflow: hidden;
	
				position: absolute;
				right: 0;
				top: 0;
				border: none;
				
				border-radius: 0 5px 5px 0;
				background: url('../img/sidebar/menu_icon_search_2x.png') center no-repeat;
				background-size: 16px auto;
				&:before{
					content:'';
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 1px;
					height: 24px;
					border-left: solid #bbccd6 1px;

				}
			}
		}
	}
	.fb, .contact{
		position: relative;
		&:before{
			content:'';
			display: block;
			position: absolute;
			top: 19px;
			left: 20px;
			width: 22px;
			height: 20px;
			background: url('../img/sidebar/header_icon_fb_2x.png');
			background-size: 100%;
		}
		a{
			padding-left: 48px;
		}
	}
	.contact{
		&:before{
			background: url('../img/sidebar/header_icon_contact_2x.png');
			background-size: 100%;
		}
	}

	background: $sidebar_main_color;
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		display:none;
		li{
			a{
				display: block;
				padding: 16px 24px;

				.sidebar-nav-icon{
					position: relative;
					margin-left: 10px;
					float: right;
					margin-top: 3px;
					display: inline-block;
					color: map-get($website_sub_color, '3');
					font-size: 21px;
					transition: all .3s;
				}
				&.open{

					.sidebar-nav-icon{
						transform: rotate(90deg);

					}

				}
				&.active{
					~ ul{
						display: block;
					}
				}
			}
		}
		&.layer-2{
			a{
				padding-left: 36px;
			}
			li:last-child{
				border: 0;
			}
		}
		&.layer-3{
			a{
				padding-left: 50px;
				position: relative;
				&:before{
					content:'';
					display: block;
					position: absolute;
					top: 25px;
					left: 35px;
					width: 8px;
					height: 8px;
					background-color: map-get($website_sub_color, '5');
				}
			}
			li:last-child{
				border: 0;
			}
		}
		//base load setting
		@each $layer,$config in $sidebar_layer_config{
			&.layer-#{$layer}{
				>li{
					>a{
						font-size: map-get($config,'font-size');
						background: map-get($config,'background');
						color: map-get($config,'color');
						.sidebar-nav-icon{
							color: map-get($config,'icon_color');
						}
						&:hover,&.open{
							background: map-get($config,'hover_background');
							color: map-get($config,'hover_color');
							.sidebar-nav-icon{
								color: map-get($config,'icon_hover_color');
							}

						}
					}
					border-bottom: map-get($config,'line_width') solid map-get($config,'line_color');
					@if $layer == '1'{
						&:first-child{
							border: none;
						}
						&:last-child{
							border-bottom: map-get($config,'line_width') solid map-get($config,'line_color');
						}
					}
				}
			}
		}

	}
	> ul{
		display:block;
	}

}



