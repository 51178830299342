.index-area{
    overflow-x: hidden;
}
.home-banner-area {
    @media screen and(min-width: $screen_sm_width){
        background-color: map-get($website_main_color, '5');        
    }
    @media screen and(min-width: $screen_sm_width){
        height: 35vw;
    }
    @media screen and(min-width: $screen_lg_width){
        height: 400px;
    }
    #home-banner-carousel {
        margin: 0 auto;
        position: relative;
        
        .owl-box{
            @media screen and(min-width: $screen_sm_width){
                position: relative;
                overflow: hidden;
                height: 35vw;
            }
            @media screen and(min-width: $screen_lg_width){
                width: 100%;
                max-width: 1140px;
                margin: 0 auto;
                height: 400px;
                position: relative;
                overflow: hidden;
            }
            .item{
            }

        }
        .banner-img {
            width: 100%;
            height: auto;


            &.for-sm{
                display: none;
            }
            @media screen and(min-width: $screen_sm_width){
                &.for-xs{
                    display: none;
                }
                &.for-sm{
                    display: block;
                }
                width: auto;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

        }
        .owl-controls{
            margin-top: 0;
            @media screen and(min-width: $screen_sm_width){
                position: absolute;
                right: 16px;
                bottom: 0;
            }
            @media screen and(min-width: 1140px){
                right: calc(50% - 570px + 16px);
            }
        }
        .owl-dot {
            span {
                width: 20px;
                height: 5px;
                background-color: #000000;
                opacity: 0.3;
                border-radius: 0;
                margin: 5px 4px;
            }

            &.active, &:hover {
                span {
                    background-color:  map-get($website_main_color, '3');
                    opacity: 1;
                }
            }
        }
    }

}

.index-block{
    margin-top: 36px;
    @media screen and(min-width: $screen_sm_width){
        margin-top: 50px;
    }
    &.gray-bg{
        background-color:  map-get($website_main_color, '5');
    }
    &.news{
        margin-top: 20px;
            @media screen and(min-width: $screen_sm_width){
            margin-top: 50px;
        }
    }
    &.join{
        padding-top: 36px;
        padding-bottom: 8px;
         @media screen and(min-width: $screen_sm_width){
            margin-top: 56px;
         }
    }
    &.about{
        margin-bottom: 36px;
        @media screen and(min-width: $screen_md_width){
            margin-bottom: 50px;
        }
    }
    &.link{
        padding-top: 36px;
        padding-bottom: 40px;
        @media screen and(min-width: $screen_md_width){
            padding-bottom: 60px;
        }
    }
    
    .index-title{
        display: block;
        text-align: center;
        h3{
            display: inline-block;
            margin: 0;
            color: map-get($website_main_color, '2');
            font-size: 22px;
            font-weight: bold;
        }
    }
    .more{
        color: map-get($website_sub_color, '7');
        font-size: 14px;
        margin-left: 6px;
    }
    .conatiner{
        @extend %clearfloat;
    }
    .news-area{
        @extend %clearfloat;
        .index-title{
            text-align: left;
            margin-bottom: 8px;
        }

        .text-list-area{
            .text-list{
                padding: 5px 0;
                &:first-child{
                    padding-top: 0;
                }
                +.text-list{
                    background: url('../img/index/dotted_line_2x.png') repeat-x left top;
                    background-size: 6px 1px;
                    @media screen and (min-width: $screen_md_width){
                        background: transparent;
                    }
                }
            }
            .date-title-area{
                @extend %clearfloat;
                position: relative;
                display: inline-block;
                vertical-align: top;
                @media screen and (min-width: $screen_md_width){
                    .date{
                        font-size: 14px;
                        float: left;
                    }
                    .title{
                        padding-left: 82px;
                        display: block;
                        font-size: 0;
                        span{
                            font-size: 15px;
                            display: inline-block;
                            overflow : hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: calc(100% - 82px);
                            vertical-align: middle;
                            max-width: 458px;
                            &.tag-cnt-1{
                                max-width: 408px;
                            }
                            &.tag-cnt-2{
                                max-width: 368px;
                            }
                        }
                        

                    }
                }
                @media screen and (min-width: $screen_lg_width){
                    .title{
                        span{
                            max-width: 660px;
                            &.tag-cnt-1{
                                max-width: 610px;
                            }
                            &.tag-cnt-2{
                                max-width: 560px;
                            }
                        }
                    }
                    
                }
                .news-tag{
                    width: 44px;
                    height: auto;
                    margin-left: 3px;
                }
            }
        }
        @media screen and(min-width: $screen_md_width){
            float: left;
            width: calc(100% - 370px);
        }
    }
    .member-area{
        padding: 25px 16px;
        background-color: map-get($website_sub_color, '5');
        border-radius: 10px;
        margin-top: 26px;
        .index-title{
            position: relative;
            @media screen and(min-width: $screen_md_width){
                text-align: left;
                padding-left: 18px;
            }
            h3{
                position: relative;
                &:before{
                    content:'';
                    display: block;
                    position: absolute;
                    bottom: 2px;
                    left: -24px;
                    width: 18px;
                    height: 22px;
                    background: url('../img/index/member_title_icon_2x.png');
                    background-size: 100%;
                    @media screen and(min-width: $screen_md_width){
                        background: url('../img/index/member_title_icon.png');
                        background-size: 100%;
                    }
                }   
            }
        }
        
        @media screen and(min-width: $screen_sm_width){
            padding: 25px 35px;
            
        }
        @media screen and(min-width: $screen_md_width){
            float: right;
            width: 370px;
            margin-top: 0;            
        }


    }

    .article-area{
        &.about{
            margin-top: 15px;
            @media screen and(min-width: $screen_md_width){
                margin-top: 22px;
            }
        }
        .guild-text{
            font-size: 15px;
            color: map-get($website_sub_color, '1');
            text-align: center;
            margin-top: 10px;
            @media screen and (min-width: $screen_md_width){
                margin: 0 auto;
                max-width: 692px;
            }
        }
        .guild-img-area{
            text-align: center;
            .guild-link-img{
                display: block;
                margin: 0 auto;
                +.guild-link-img{
                    margin-top: 18px;
                }
            }
            @media screen and (min-width: $screen_sm_width){
                .guild-link-img{
                    display: inline-block;
                    vertical-align: top;
                    +.guild-link-img{
                        margin-top: 0px;
                        margin-left: 25px;
                    }
                }
            }
        }
    }
    
    #home-link-carousel{
        padding: 0 26px;
        margin-top: 20px;
        @media screen and (min-width: $screen_md_width){
            margin-top: 30px;
        }
        .owl-stage-outer{
            position: relative;
        }
        .owl-controls{
            margin: 0;
        }

        .owl-box{
            border-radius: 10px;
            overflow: hidden;
            border: 3px solid #FFF;
            max-width: 263px;
            width: 100%;
            margin: 0 auto;
            .item{
                display: block;
                height: 0;
                padding-bottom: 36.97%;
                position: relative;
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .link-prev-slide, .link-next-slide{
            position: absolute;
            top: 50%;
            margin-top: -14px;
            img{
                width: 13px;
                height: auto;
            }
        }
        .link-prev-slide{
            left: 0;
        }
        .link-next-slide{
            right: 0;
        }
    }
}
#forget_pw_modal{
    .modal-title{
        display: inline-block;
    }
    
}
.flow-area{
    text-align: center;
    margin-top: 25px;
    $join_icon_config : (
        '1': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        '2': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        '3': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        '4': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        '5': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        '6': (
            'bg_color': map-get($website_main_color, '4'),
            'active_bg_color': map-get($website_main_color, '4'),
            'border_color': transparent
        )
    );
    &.row{
        margin-left: -10px;
        margin-right: -10px;
        @media screen and(min-width: 400px){
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .col-xs-6 {
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        span{
            display: block;
            margin-top: 12px;
            margin-bottom: 24px;
        }
        &:before{
            content:"";
            display: block;
            position: absolute;
            top: 48px;
            left: -4px;
            width: 15px;
            height: 19px;
            background: url('../img/index/process_arrow01_2x.png');
            background-size: 100%;

        }
        &:last-child{
            &:before{
                background: url('../img/index/process_arrow02_2x.png');
                background-size: 100%;
            }
        }
        &:first-child, &:nth-child(3), &:nth-child(5){
            &:before{
                display: none;
            }
        }
        @media screen and(min-width: 400px){
            padding-left: 15px;
            padding-right: 15px;
        }
        @media screen and(min-width: 600px){
            width: calc(100% / 3);
            &:nth-child(4){
                &:before{
                    display: none;
                }
            }
            &:nth-child(3), &:nth-child(5){
                &:before{
                    display: block;
                }
            }
        }
        @media screen and(min-width: $screen_md_width){
            width: calc(100% / 6);
            &:nth-child(4){
                &:before{
                    display: block;
                }
            }
        }
    }
    .flow-icon{
        width: 110px;
        height: 110px;
        border-radius: 50%;
        margin: 0 auto;
        @each $step,$config in $join_icon_config{
            &.step-#{$step}{
                border: 2px solid map-get($config, 'border_color');
                background-color: map-get($config, 'bg_color');
                position: relative;
                transition: all .5s;
                &:after{
                    content:'';
                    display: block;
                    position: absolute;
                    top: calc( 50% - (73px / 2));
                    left: calc( 50% - (73px / 2));
                    width: 73px;
                    height: 73px;
                    background: url('../img/index/process_pic0#{$step}_off_2x.png')no-repeat center;
                    background-size: 73px;
                    @media screen and(min-width: $screen_md_width){
                        background: url('../img/index/process_pic0#{$step}_off.png')no-repeat center;
                        background-size: 73px;
                    }
                }
                &.active{
                    background-color: map-get($config, 'active_bg_color');
                    border: 2px solid map-get($website_main_color, '3');
                    &:after{
                        background-image: url('../img/index/process_pic0#{$step}_on_2x.png');
                        background-size: 73px;
                        background-repeat: no-repeat;
                        background-position: center;
                        @media screen and(min-width: $screen_md_width){
                            background: url('../img/index/process_pic0#{$step}_on.png')no-repeat center;
                            background-size: 73px;
                        }
                    }
                }
            }
        }
        &.step-1.fixed-bg{
             background-color: map-get($website_main_color, '3');
             border: 2px solid map-get($website_main_color, '3');
        }
    }
 
    
}
.home-member-box{
    font-size: 15px;
    color: map-get($website_main_color, '1');
    .top-area{
        padding-top: 6px;
        padding-bottom: 90px;
    }
    .bottom-area{
        padding-top: 30px;
        background: url('../img/index/index_member_dotted_line_2x.png') top left repeat-x;
        background-size: 12px auto;
        @media screen and(min-width: $screen_md_width){
            background: url('../img/index/index_member_dotted_line.png') top left repeat-x;
            background-size: 12px auto;
        }

    }
}