$website_main_color : (
	'1' : #454545, 
	'2' : #58656f, 
	'3' : #267dc0,
	'4' : #109a88,
	'5' : #f7f7f7
);

$website_sub_color : (
	'1' : #5a5d5e,
	'2' : #649acc,
	'3' : #78b5cf,
	'4' : #bfe0ed,
	'5' : #cde9f4,
	'6' : #47b8a9,
	'7' : #de6b6b,
	'8' : #e69c66,
	'9' : #8d99a3,
	'10' : #ffffff,
	'11' : #dfdfdf,
);

$global_default_setting : (
	'font-size' : 17px,
	'color' : map-get($website_main_color,'1'),
	'line-height' : 1.7,
	'font-family' : "'Arial','Microsoft JhengHei'"
);

 
$screen_xl_width: 1300px;
$screen_lg_width: 1200px;
$screen_md_width: 992px; 
$screen_sm_width: 768px;
$screen_xs_width: 640px;
$screen_xss_width: 400px;
  
$container_lg: 1170px;
$container_md: 970px;



body {
	position: relative;
	width: 100%;
	overflow-x: hidden;
	word-break: break-word;
	@each $setting, $value in $global_default_setting {
		#{$setting} : #{$value};
	}
}

a {
	text-decoration: none;
	color: map-get($global_default_setting,'color');
	&:focus,&:hover,&:visited{
		text-decoration: none; 
	}
}



@each $idx,$color in $website_main_color{
	.color-main-#{$idx}{
		color: $color;
	}
}


@each $idx,$color in $website_sub_color{
	.color-sub-#{$idx}{
		color: $color;
	}
}



.container {
	width: 100%;
	padding-right: 10px;
    padding-left: 10px;
	@media screen and (min-width: $screen_xss_width) {
		padding-right: 15px;
	    padding-left: 15px;
	}

	@media screen and (min-width: $screen_md_width) {
		width: $container_md;
		
	}
	@media screen and (min-width: $screen_lg_width) {
		width: $container_lg;
	}
	&.content{
		max-width: 1170px;
	}
}


//數字input不要上下箭頭
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none; }
input[type=number] {
  -moz-appearance: textfield; 
}

.btn{
	&:hover,&:active,&:focus{
		box-shadow: none;
		&:hover,&:active,&:focus{
			box-shadow: none;
		}
	}
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}


.owl-carousel{
	visibility: hidden;
	&.owl-loaded{
		visibility: visible;
	}	
}

.invisible {
  visibility: hidden !important;
}



@import "model/base";
@import "model/photolist";
@import "model/utility";
/*
=================================================================
(#m02-1hds) HEADER
=================================================================
*/
@import "base/head";
@import "base/sidebar";

/*
=================================================================
(#m02-2fts) FOOTER
=================================================================
*/
@import "base/foot";
/*
=================================================================
(#m03mcs) MAIN CONTENT
=================================================================
*/
@import "page/page";
@import "page/form";
@import "page/index";
@import "page/list";

