@mixin photo-list-box($photo_position,$xs_block,$photo_area_width,$photo_area_height,$content_padding) {
	position: relative;
	@if $xs_block == false {
		@if $photo_position == left {
			padding-left: $photo_area_width;
			.photo-area,.iframe-area {
				left: 0px;
			}
			.content-area {
				padding-left: $content_padding;
			}
		}
		@else if $photo_position == right {
			padding-right: $photo_area_width;
			.photo-area,.iframe-area {
				right: 0px;
			}
			.content-area {
				padding-right: $content_padding;
			}
		}
		.photo-area,.iframe-area {
			position: absolute;
			top: 0px;
			width: $photo_area_width;
			height: $photo_area_height;
		}
		.content-area {	
			position: relative;
			min-height: $photo_area_height;
		}
	}	
	@else if $xs_block == true {
		.photo-area,.iframe-area {
			text-align: center;
			img {
				margin-bottom: 10px;
			}
			iframe {
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}
	@media screen and (min-width: $screen_sm_width) {
		@if $photo_position == left {
			padding-left: $photo_area_width;
			.photo-area,.iframe-area {
				left: 0px;
			}
			.content-area {	
				padding-left: $content_padding;
			}
		}
		@else if $photo_position == right {
			padding-right: $photo_area_width;
			.photo-area,.iframe-area {
				right: 0px;
			}
			.content-area {	
				padding-right: $content_padding;
			}
		}
		.photo-area,.iframe-area {
			position: absolute;
			top: 0px;
			width: $photo_area_width;
			height: $photo_area_height;
		}
		.content-area {	
			position: relative;
			min-height: $photo_area_height;
		}
	}
	.photo-area,.iframe-area {
		img {
			max-width: 100%;
			max-height: 100%;
		}
		iframe {
			max-width: 100%;
			max-height: 100%;
		}
	}
}