.required{
	position: relative;
	&:before{
		content:'*';
		position: relative;
		top: 5px;
		line-height: 1;
		margin-right: 6px;

		display: inline-block;
		color: map-get($website_sub_color,'7');
		font-size: 18px;

		@media screen and (min-width: $screen_sm_width) {
			position: absolute;
			margin: 0;
			top: 1px;	
			left: -9px;
		}
	}
}


.control-label{
	font-weight: normal;
	font-size: 15px;
    padding-top: 3px;
    margin-bottom: 0;
    text-align: right;
}
@mixin form-group-width($label_width){
	>.control-label{
		width: #{$label_width};
		padding-left: 0;
		float: left;
	}
	>div{
		float: left;
		padding-right: 0;
		padding-left: 0;
		width: calc(100% - #{$label_width});
	}
}
.column-system{
	&.two-column{
		&.type1{
			@media screen and (min-width: $screen_sm_width){
				@include two-column-system(36%);
			}
			@media screen and (min-width: $screen_lg_width){
				@include two-column-system(258px);
			}
		}
		
		&.for-search{
			@media screen and (min-width: $screen_sm_width){
				@include two-column-system(130px);
			}
			
		}

		&.half{
			@media screen and (min-width: $screen_sm_width){
				@include two-column-system(50%);
			}
		}
		&.type3{
			@media screen and (min-width: $screen_sm_width){
				@include two-column-system(40%);
			}
		}
		&.type4{
			@media screen and (min-width: $screen_sm_width){
				@include two-column-system(45%);
			}
		}
	}
}
.form-group-area{
	margin: 12px 0 25px 0;
	@media screen and (min-width: $screen_md_width){
		margin-top: 18px;
	}
}
body:not(.index){
	.vcode_refresh{
		&:hover{
			background-color: map-get($website_main_color, '3');
			i{
				color: map-get($website_sub_color, '5');
			}
		}
	}
}
.form-group{
	margin-top: 15px;
	margin-bottom: 0;

	margin-left: 0 !important;
	margin-right: 0 !important;
	border: none;
	font-size: 15px;
	&:first-child{
		margin-top: 0;
	}
	@extend %clearfloat;

	.control-label{
		padding-right: 5px;
		margin-bottom: 4px;
	}

	
	
	.vcode-area{
		@extend %clearfloat;
		>*{
			float: left;
		}
		>input{
			width: calc(100% - 34px - 10px - 113px - 10px);
		}
		.vcode_img{
			margin-left: 10px;
			width: 113px;
			padding: 0;
			height: 35px;
			border-radius: 5px;

		}
		.vcode_refresh{
			cursor: pointer;
			margin-left: 10px;
			padding: 0;
			text-align: center;
			width: 34px;
			line-height: 34px;
			background-color: map-get($website_sub_color, '5');
			border-radius: 5px;
			i{
				color: map-get($website_main_color, '3');
				font-size: 23px;
				position: relative;
				top: 2px;
   				left: 1px;
			}
			
		}
	}
	.form-control{
		border-radius: 5px;
		border: 1px solid #bbccd6;
		
	}
	input.form-control, select.form-control{
		height: 35px;
	}
    &.type3{ //會員登入
    	@extend %clearfloat;
    	margin-bottom: 10px;
		@media screen and(min-width: $screen_sm_width){
			width: 100%;
			margin-bottom: 0;
		}
		.control-label{
			padding-top: 5px;
			&.vcode{
				width: 55px;
			}
			@media screen and(min-width: $screen_sm_width){
				width: 55px;
				float: left;
			}
			@media screen and(min-width: $screen_md_width){
				width: 35px;
			}
		}
		>div{
			width: 100%;
			@media screen and(min-width: $screen_sm_width){
				float: left;
				width: calc(100% - 55px);
			}
			@media screen and(min-width: $screen_md_width){
				width: calc(100% - 35px);
			}
		}

		.vcode-area{
		    margin-bottom: 20px;
			.row{
				float: none;
				margin-right: -5px;
				margin-left: -5px;
			}
			.col-sm-6{
				@extend %clearfloat;
				padding-right: 5px;
				padding-left: 5px;
				input{
					width: 100%;
					margin-bottom: 20px;
					@media screen and(min-width: $screen_sm_width){
						margin-bottom: 0;
					}
				}
			}
			@media screen and(min-width: $screen_sm_width){
				margin-bottom: 0;
				width: calc(100% - 55px);
				max-width: none;
				input{
					width: calc(100% - 34px - 10px - 160px - 10px);
				}
				.vcode_img{
					margin: 0;
					width: 160px;
				}

			}
			.vcode_img{
				margin: 0;
				width: calc(100% - 35px - 10px);
				max-width: 160px;
				float: left;
			}
			.vcode_refresh{
				display: inline-block;
				float: left;
				width: 35px;
			}
		}
		.pw-input{
			@media screen and(min-width: $screen_sm_width){
				width: 	calc(100% - 55px);	
			}
			@media screen and(min-width: $screen_md_width){
				width: 	calc(100% - 70px - 10px - 35px);	
			}
		}
		.forget-pw{
			display: inline-block;
			color: map-get($website_sub_color, '7');
			font-size: 15px;
		    margin-left: 10px;
    		margin-top: 5px;
    		width: 70px;
    		float: right;
		}
		.btn{
			width: 100%;
		}
	}
	@include placeholder {
		color: #c2c2c2;
		font-size: 14px;
	}


}
.member-area{	
	.submit{
	    width: 100%;
	    margin-top: 15px;
	    background-color: map-get($website_main_color, '3');
	    border-radius: 5px;
	    border: 0;
	    color: #FFF;
	    padding: 6px 0;
	    &:hover{
			background-color: #1f73b4;
	    }
	}
}
.member-btn-area{
	width: 100%;
	$member_btn_config : (
		'forget': (
			'number': '1'
		),
		'sign-in': (
			'number': '2'
		),
		'zone': (
			'number': '3'
		),
		'maintain':(
			'number': '4'
		),
		'lesson':(
			'number': '5'
		)
	);
	.row{
		margin-left: -6px;
		margin-right: -6px;
		.col-xs-4{
			padding-right: 6px;
			padding-left: 6px;
		}
		@media screen and (min-width: 360px){
			margin-left: -12px;
			margin-right: -12px;
			.col-xs-4{
				padding-right: 12px;
				padding-left: 12px;
			}
		}
	}
	.member-btn{
		color: map-get($website_main_color, '1');
		font-size: 14px;
		position: relative;
		padding-left: 25px;
		&:hover{
			color: map-get($website_main_color, '3');
		}

		@each $icon,$config in $member_btn_config{
			&.#{$icon}{
				position: relative;
				$number: map-get($config, 'number');
				span{
					position: relative;
					&:before{
						content:'';
						display: block;
						position: absolute;
						top: -3px;
						left: -24px;
						width: 19px;
						height: 22px;
						background: url('../img/index/index_member_icon0#{$number}_2x.png');
						background-size: 100%;
						@media screen and (min-width: $screen_md_width){
							background: url('../img/index/index_member_icon0#{$number}.png');
							background-size: 100%;
						}
					}
				}
				@if $icon == maintain{
					padding-left: 21px;
				}
				
			}

		}

	}
}
.main_right_area{
	.search-area{
		@extend %clearfloat;
		background-color: map-get($website_main_color, '5');
		padding: 10px 20px;
		&.welfare{
			.row{
				margin-left: -10px;
				margin-right: -10px;
				[class*="col-"]{
					padding-right: 10px;
					padding-left: 10px;
				}
			}
			.search-btn{
				width: 100%;
				height: 30px;
				background-color: map-get($website_sub_color, '3');
				font-size: 15px;
				border-radius: 5px;
				color: #FFF;
				border: 0;
				margin-top: 15px;
				@media screen and (min-width: $screen_sm_width){
					margin-top: 0;
				}
				&:hover{
					background-color: map-get($website_main_color, '3');
				}
			}

		}

	}

	.form-group{
		@extend %clearfloat;
		.control-label{
			color: map-get($website_sub_color, '1');
			font-size: 15px;
			vertical-align: middle;
			
		}
		.custom-radio-style{
			font-weight: normal;
			margin-bottom: 0;
			padding-top: 3px;
			font-size: 15px;
			input[type='radio']{
				margin-right: 5px;
			}
			+.custom-radio-style{
				margin-left: 12px;
			}

		}
		&.type1{ //news

			.control-label{
				text-align: left;
				padding-top: 3px;
				width: 86px;
			}
			>div{
				display: inline-block;
				padding-left: 0px;
				width: calc(100% - 86px);
				select{

					vertical-align: middle;
					border-radius: 5px;
					border: #e7e7e7 1px solid;
					padding: 3px 14px 5px 14px;
					width: 100%;
					max-width: 300px;
					font-size: 15px;
					height: 30px
				}

			}
		}
		&.type2{ //會員申請 線上課程申請...
			margin-top: 0;
			background: url('../img/index/dotted_line_2x.png') repeat-x left top;
			padding: 10px 0 ;
            background-size: 6px 1px;
            &:first-child{
            	background: none;
            }

			@media screen and (min-width: $screen_sm_width) {
				@include form-group-width(186px);
			}
			
			.row{
				margin-right: -5px;
				margin-left: -5px;

				[class*="col-"]{
					padding-left: 5px;
					padding-right: 5px;
				}
				.xs-verticle{
					+.xs-verticle{
						margin-top: 12px;
						@media screen and (min-width: $screen_sm_width) {
							margin-top: 0;
						}
					}
				}
			}
			select{
				display: block;
			    width: 100%;
			    height: 35px;
			    padding: 6px 12px;
			    font-size: 14px;
			    line-height: 1.42857143;
			    color: #555;
			    background-color: #fff;
			    border-radius: 5px;
    			border: 1px solid #bbccd6;
			}
			.vcode_refresh{
				background-color: map-get($website_sub_color, '5');
				border-radius: 5px;
				&:hover{
					background-color: map-get($website_main_color, '3');
					i{
						color: map-get($website_sub_color, '5');
					}
				}
			}

		}
		&.type4{ //會員福利
			.control-label{
				padding-top: 1px;
			}
			@media screen and (min-width: $screen_sm_width) {
				@include form-group-width(80px);
			}
			.form-control{
				height: 30px;
				border: #e7e7e7 1px solid;
			}

		}
		&.type5{ //聯絡我們
			margin-top: 0;
			background: url('../img/index/dotted_line_2x.png') repeat-x left top;
			padding: 10px 0 ;
            background-size: 6px 1px;
            &:first-child{
            	background: none;
            }
			@media screen and (min-width: $screen_sm_width) {
				@include form-group-width(95px);
			}
		}
		.date-col{
			@extend %clearfloat;
			input{
				float:left;
				width: calc( (100% - 20px) / 2 );
			}
			span{
				float:left;
				width: 20px;
				text-align: center;
			    font-size: 15px;
			}
		}
		&.text-only{
			.control-label{
				padding-top: 0;
			}
			>div{
				font-size: 15px;
				color: map-get($website_sub_color, '1');
			}
		}
		.article-area{
			padding: 0;
		}
	}
	.btn-area{
		margin-top: 20px;
		text-align: center;
		@media screen and (min-width: $screen_sm_width) {
			text-align: right;
		}
		.btn{ 
			height: 35px;
			line-height: 34px;
			width: calc( (100% - 10px) / 2 );
			line-height: 34px;
			padding: 0px 38px;

			@media screen and (min-width: $screen_sm_width) {
				width: 205px;
			}
			+.btn{
				margin-left: 5px;
			}
		}
	}
	.btn{
		
		color: map-get($website_sub_color, '10');
		font-size: 15px;
		
		&.green-bg{
			background-color: map-get($website_sub_color, '6');
			&:hover{
				background-color: #109a88;
			}
		}
		&.blue-bg{
			background-color: map-get($website_main_color, '3');
			&:hover{
				background-color: #1f73b4;
			}
		}
		&.member-edit{
			width: 220px;
			display: block;
			margin: 0 auto;
			@media screen and (min-width: $screen_sm_width) {
				width: 100%;

			}
		}
	}
	.separate-block{
		font-size: 16px;
		color: map-get($website_sub_color, '1');
		background-color: map-get($website_main_color, '5');
		padding: 11px 0 11px 20px;
	}
	.pick-file{
		width: 120px;
	    position: relative;
	    height: 35px;
	    background: map-get($website_main_color, '3');
	    border-top-right-radius: 5px;
	    border-bottom-right-radius: 5px;
	    color: #fff;
	    border: 0px;
	    line-height: 35px;
	    padding: 0;
	    cursor: pointer;
	    i{
	    	position: relative;
	    	bottom: 2px;
	    }
	    input[type="file"] {
		    position: absolute;
		    max-width: 100%;
		    max-height: 100%;
		    height:35px;
		    opacity: 0;
		    left: 0;
		    top: 0;
		    z-index: 100;
		    cursor: pointer;
		}
	}
}
.paid-info-area{
	textarea{
		border-radius: 5px;
    	height: 188px;
    	font-size: 15px;
    	color: map-get($website_sub_color, '1');
	}
}
button{
	font-size: 15px;
}
button:focus, select:focus, input:focus, textarea:focus{
	outline: 0 !important;
	box-shadow: none !important;
}