
.main_content_area{
	display: table;
	width: 100%;
	margin-bottom: 60px;
	.main_left_area{
		display: none;
		@media screen and (min-width: $screen_md_width){
			display: table-cell;
			vertical-align: top;
			width: 222px;		
		}
	}
	.main_right_area{
		display: table-cell;
		vertical-align: top;
		@media screen and (min-width: $screen_md_width){
			padding-left: 30px;
		}

	}
	.article-area{
		padding-top: 15px;
	}
}
.article-area , #tinymce{
	@extend %edit-area;
}
.page-title{
	h1{
		margin: 4px 0 0 0;
		color: map-get($website_main_color, '2');
		font-size: 18px;
		line-height: 1.7;
	}
	.login-page-icon{
		padding-left: 22px;
		&.member{
			background: url('../img/page/member_title_icon_2x.png') left bottom 6px no-repeat;
			background-size: 18px auto;
		}
		&.flow{
			background: url('../img/page/apply_title_icon_2x.png') left bottom 6px no-repeat;
			background-size: 18px auto;
		}
	}
}
.page-top-area{
	@extend %clearfloat;
	.page-title{
		display: inline-block;
	}
	.page-date{
		color: map-get($website_sub_color, '6');
		font-size: 15px;
		margin-bottom: -4px;
	}
	
	border-bottom: 2px solid  map-get($website_sub_color, '11');
	padding-top: 10px;
	padding-bottom: 11px;
	&.has-date{
		padding-top: 1px;	
	}
	.print-btn{
		display: inline-block;
		float: right;
		background-color: map-get($website_sub_color, '6');
		border-radius: 5px;
		color: #FFF;
		padding: 3px 14px;
		font-size: 15px;
		&:hover{
			background-color: #109a88;
		}
	}
}
.page-banner-area{
	background-color: map-get($website_main_color, '5');
	background-image: url('../img/page/banner_title_bg_pic_2x.png'), url('../img/page/banner_pic.png');
	background-repeat: no-repeat;
	background-position: left -5px top 22px, right -19px bottom -2px;
	background-size: 85px auto, 250px auto;
	position: relative;
	@media screen and(min-width: 600px){
		background-size: 104px auto, 360px auto;
	}
	@media screen and(min-width: $screen_sm_width){
		background-image: url('../img/page/banner_title_bg_pic.png'), url('../img/page/banner_pic.png');
		background-position: left calc(50% - 436px) top 13px, right calc(50% - 210px) bottom -2px;
		background-size: 104px auto, 450px auto;
	}

	@media screen and(min-width: $screen_lg_width){
		background-position: left calc(50% - 478px) top 13px, right calc(50% - 272px) bottom -2px;
		background-size: 104px auto, 533px auto;
	}
	.page-banner-title{
		height: 130px;
		padding-top: 38px;
		padding-left: 25px;
		padding-right: 10px;
		position: relative;
		@media screen and (min-width: $screen_sm_width){
			padding-left: 50px;
			padding-top: 50px;
		}

		@media screen and (min-width: $screen_lg_width){
			padding-left: 108px;
		}
		.ch-title{
			display: inline-block;
			font-size: 20px;
			font-weight: bold;
			color: map-get($website_main_color, '1');
			@media screen and (min-width: $screen_sm_width){
				font-size: 21px;
			}

		}
		.en-title{
			display: inline-block;
			font-size: 18px;
			color: map-get($website_main_color, '3');
			margin-left: 2px;
			@media screen and (min-width: $screen_sm_width){
				font-size: 22px;
			}
		}
		
	}

}
.left-menu{

	&.layer2{
		list-style: none;
		padding: 0;
		>li{
			+li{
				border-top: solid 1px #eaeaea;
			}
			background-color: map-get($website_main_color, '5');
			position: relative;
			
			&:first-child{
				border-top: solid 1px map-get($website_sub_color, '11');
			}
			&:last-child{
				border-bottom: solid 1px map-get($website_sub_color, '11');
			}
			>a{
			    display: block;
			    padding: 15px 15px 15px 38px;
			    position: relative;
			    font-size: 15px;
			    color: map-get($website_sub_color, '1');
			    background: url('../img/page/menu_icon_off_2x.png') no-repeat;
			    background-size: 16px;
			    background-position: left 14px top 20px;
			}
			&:hover, &.active{
				border-top: solid 1px map-get($website_main_color, '3');
				background-color: map-get($website_main_color, '3');
				&:before{
					content:'';
					display: block;
					position: absolute;
					bottom: -1px;
					left: 0;
					width: 100%;
					height: 1px;
					border-bottom: solid 1px #eaeaea;
					z-index: 1;
				}
				>a{
					color: map-get($website_sub_color, '10');
					background: url('../img/page/menu_icon_on_2x.png') no-repeat;
			    	background-size: 16px;
			    	background-position: left 14px top 20px;

				}
			}
			&:hover{
			}
			&.active{
				&:after{
					content:"";
					display: block;
					position: absolute;
					top: 20px;
					right: -8px;
					@include css-triangle(right, map-get($website_main_color, '3'), 17px, 8px);
				}
			}
		}
	}
	&.layer3{
		list-style: none;
		padding: 6px 0 0 0;
		background-color: #fff;
		>li{
			position: relative;
			&:before{
				content:"";
				display: block;
				position: absolute;
				top: 15px;
				left: 17px;
				width: 8px;
				height: 8px;
				background-color: map-get($website_sub_color, '4');
			}
			a{
				display: block;
				color: map-get($website_sub_color, '1');
				padding: 6px 38px;
				font-size: 15px;
			}
			&:hover, &.active{
				&:before{
					background-color: map-get($website_main_color, '3');
				}
				a{
					color: map-get($website_main_color, '3');
				}

			}
		}
	}
}

.page-bottom-area{
	@extend %clearfloat;
	border-top: 2px solid map-get($website_sub_color, '11');
	margin-top: 40px;
	padding-top: 20px;
	.page-btn-area{
		@extend %clearfloat;
		.share-box{
			float: right;
		}
		.btn-back{
			display: block;
			float: left;
			color: map-get($website_sub_color, '10');
			font-size: 15px;
			background: map-get($website_main_color, '2');
			border-radius: 5px;
			padding: 4px 20px;
		}
		.share-btn{
			display: inline-block;
			width: 36px;
			height: 36px;
			&.facebook{
				background: url('../img/page/icon_fb_2x.png') no-repeat center;
				background-size: 100%;
				&:hover{
					background: url('../img/page/icon_fb_on_2x.png') no-repeat center;
					background-size: 100%;
				}
			}
			&.line{
				margin-left: 6px;
				background: url('../img/page/icon_line_2x.png') no-repeat center;
				background-size: 100%;
				&:hover{
					background: url('../img/page/icon_line_on_2x.png') no-repeat center;
					background-size: 100%;
				}
			}
		}
	}
	.signup-download-btn-area{
		float: left;
		.btn{
			display: inline-block;
			font-size: 13px;
			padding: 0 8px;
			color: #FFF;
			height: 34px;
			line-height: 34px;
			
			@media screen and (min-width: 420px){
				font-size: 15px;
				padding: 0px 16px;
			}
			+.btn{
				margin-left: 10px;
			}
			&.edu_submit{
				background-color: map-get($website_main_color, '3');
				&:hover{
					background-color: #1f73b4;
				}
			}
			&.edu_down{
				background-color: map-get($website_sub_color, '6');
				&:hover{
					background-color: #109a88;
				}
			}

		}
	}
	
}
.modal{
	.modal-title{
		display: inline-block;
	}
}
.lesson-info-area{
	padding: 10px 18px;
	background-color: map-get($website_main_color, '5');
	color: map-get($website_main_color, '2');
	font-size: 15px;
	.alert-red{
		color: red;
	}
	.lesson-info{
		+.lesson-info{
			margin-top: 7px;
		}
	}
	@media screen and (min-width: $screen_lg_width){
		.lg-inline-block{
			display: inline-block;
			+.lg-inline-block{
				margin-left: 24px;
			}
		}
	}

}
.login-bottom-area{
	@extend %clearfloat;
	margin-top: 20px;
	@media screen and (min-width: $screen_sm_width){
		margin-top: 52px;	
	}
	.article-area{
		padding-top: 6px;
		margin-bottom: 14px;
	}
	.btn-area{
		text-align: center;
		@media screen and (min-width: $screen_sm_width){
    		text-align: right;
	    }

	}
	.signup-btn{
	    display: inline-block;
	    padding: 5px 40px;
	    @media screen and (min-width: $screen_sm_width){
    		padding: 5px 68px;
	    }

		border-radius: 5px;
		color: map-get($website_sub_color, '10');
		font-size: 15px;
		background-color: map-get($website_sub_color, '6');
		&:hover{
			background-color: #109a88;
		}
	}
}
.login-form-area{
	@extend %clearfloat;
	margin-top: 20px;
	.sm-second-row{
		@media screen and (min-width: $screen_sm_width){
			margin-top: 15px;
		}
		@media screen and (min-width: $screen_md_width){
			margin-top: 30px;
			&.vcode{
				margin-top: 0;
			}
		}
	}

	
	.btn{
	    border-radius: 5px;
		text-align: center;
		color: map-get($website_sub_color, '10');
		font-size: 15px;

		&.green-bg{
			background-color: map-get($website_sub_color, '6');
			&:hover{
				background-color: #109a88;
			}
		}
		&.blue-bg{
			background-color: map-get($website_main_color, '3');
			&:hover{
				background-color: #1f73b4;
			}
		}
		+.btn{
			margin-left: 5px;
		}
	}
	
	
}
.member-index-top-area{
	padding-top: 15px;
	padding-bottom: 20px;
	@media screen and (min-width: $screen_sm_width){
		padding-bottom: 40px;

	}
	.col-xs-12{
		@media screen and (min-width: 600px){
			width: 50%;
		}
	}
	.member-info{
		color: map-get($website_sub_color, '1');
		font-size: 15px;
		margin-bottom: 15px;
		.no-pay{
			color: map-get($website_sub_color, '7');
			font-size: 15px;
		}
	}
	
}
.member-index-bottom-area{

	padding-top: 30px;
	$member_link_config : (
        'edit': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        'pay': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        'course': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        'point': (
            'bg_color': map-get($website_sub_color, '5'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': map-get($website_sub_color, '4')
        ),
        'logout': (
            'bg_color': map-get($website_main_color, '3'),
            'active_bg_color': map-get($website_main_color, '3'),
            'border_color': transparent
        )
    );
	@extend %clearfloat;
	background: url('../img/index/dotted_line_2x.png') 0 0 repeat-x;
	background-size: 6px 1px;
	.col-xs-6{
		margin-bottom: 20px;
		@media screen and (min-width: $screen_md_width){
			margin-bottom: 0;
		}
	}
	.col-sm-4{
		@media screen and (min-width: $screen_md_width){
			width: 20%;
		}
	}

	.member-link{
		border-radius: 50%;
		display: block;
		width: 110px;
		height: 110px;
		@each $link,$config in $member_link_config{
            &.#{$link}{
                border: 2px solid map-get($config, 'border_color');
                background-color: map-get($config, 'bg_color');
                position: relative;
                margin: 0 auto;
                &:after{
                    content:'';
                    display: block;
                    position: absolute;
                    top: calc( 50% - (73px / 2) );
                    left: calc( 50% - (73px / 2) );
                    width: 73px;
                    height: 73px; 
                    @if $link == logout{
                    	background: url('../img/member/index_icon_logout_2x.png')no-repeat center;
	                    background-size: 73px;
	                    @media screen and(min-width: $screen_md_width){
	                        background: url('../img/member/index_icon_logout.png')no-repeat center;
	                        background-size: 73px;
	                    }
                    }
                    @else{
	                    background: url('../img/member/index_icon_#{$link}_off_2x.png')no-repeat center;
	                    background-size: 73px;
	                    @media screen and(min-width: $screen_md_width){
	                        background: url('../img/member/index_icon_#{$link}_off.png')no-repeat center;
	                        background-size: 73px;
	                    }
                    }
                }
                &:hover{
                	@media screen and (min-width: $screen_md_width) {
	                    background-color: map-get($config, 'active_bg_color');
	                    border: 2px solid map-get($website_main_color, '3');
	                    &:after{
	                        background-repeat: no-repeat;
	                        background-position: center;
	                        @if $link == logout{
		                    	background: url('../img/member/index_icon_logout_2x.png')no-repeat center;
			                    background-size: 73px;
			                    @media screen and(min-width: $screen_md_width){
			                        background: url('../img/member/index_icon_logout.png')no-repeat center;
			                        background-size: 73px;
			                    }
		                    }
		                    @else{
		                        background-image: url('../img/member/index_icon_#{$link}_on_2x.png');
		                        background-size: 73px;
		                        @media screen and(min-width: $screen_md_width){
		                            background: url('../img/member/index_icon_#{$link}_on.png')no-repeat center;
		                            background-size: 73px;
		                        }

		                    }
	                    }
                    }
                }
            }
        }

	}
	span{
		text-align: center;
    	display: block;
		color: map-get($website_sub_color, '1');
		font-size: 15px;
		margin-top: 10px;
	}

}
.no-pay-error, .alert-red{
	color: map-get($website_sub_color, '7');
	font-size: 15px;
}
.alert-blue{
	color: map-get($website_main_color, '3');
	font-size: 15px;
}
.jump-page-box{
	margin-top: 40px;
	text-align: center;
	@media screen and (min-width: $screen_md_width){
		margin-top: 76px;
		&.success{
			margin-top: 120px;
		}
	}

	.img-area{
		img{
			margin: 0 auto;
		}
		&.page-404{
			.for-xs{
				width: 226px;
				height: auto;
			}
			.for-md{
				width: 348px;
				height: auto;
			}
		}
		&.success{
			.for-xs{
				width: 215px;
				height: auto;
			}
			.for-md{
				width: 215px;
				height: auto;
			}
		}
		.for-md{
			display: none;
		}
		@media screen and (min-width: $screen_md_width){
			.for-xs{
				display: none;
			}
			.for-md{
				display: block;
			}
		}
	}
	.text-area{
		display: inline-block;
		text-align: left;
		color: map-get($website_main_color, '2');
		font-size: 24px;
		margin-top: 22px;
		@media screen and (min-width: $screen_md_width){
			text-align: center;
			margin-top: 18px;
		}
		.error-time{
			color: map-get($website_sub_color, '7');
			font-size: 24px;
		}
	}
	
}
.site-map{
	margin-top: 20px;

	@media screen and (min-width: $screen_md_width){
		margin-top: 68px;
	}
	.col-xs-12{
	    margin-bottom: 25px;
	    @media screen and (min-width: 470px){
	    	width: 50%;
	    }
	    @media screen and (min-width: 600px){
			width: calc(100% / 3);
	    }
		@media screen and (min-width: $screen_md_width){
			width: 20%;	
		    margin-bottom: 40px;
		}
		
	}
	.clearfix{
		&.visible-two-block{
			display: none;
			@media screen and (min-width: 470px){
	    		display: block;
	    	}
	    	@media screen and (min-width: 600px){
	    		display: none;
	    	}
		}
		&.visible-three-block{
			display: none;
			@media screen and (min-width: 600px){
	    		display: block;
	    	}
	    	@media screen and (min-width: $screen_md_width){
	    		display: none;
	    	}
		}
	}
	.unit{
		display: block;
		&.layer1{
			color: map-get($website_main_color, '3');
			font-size: 17px;
			margin-bottom: 5px;
		}
		&.layer2{
			color: map-get($website_main_color, '2');
			font-size: 15px;
			margin-bottom: 5px;
			&:last-child{
				margin-bottom: 0;		
			}
			&:hover{
				color: #0b9c89
			}

		}
	}
}
.gsc-control-cse{
    border: none !important;
    .gsc-input-box{
        border: 0;
        border-radius: 0;
        height: 33px;
        padding: 0 !important;
    }
    form.gsc-search-box{
    	td{
    		padding: 0;
    	}
        border: 1px solid rgba(0,0,0,0);
        box-shadow: none;
        background: #cde9f4;
        *{
        	background: #cde9f4;
        }
        margin: 0;
        padding: 8px 12px;
        input{
        	padding-left: 15px !important;
        	height: 33px !important;
        	background-position: left 15px center !important;
        }
	    .gsc-search-button-v2{
	        background-color: #267dc0;
	        *{
		        background-color: #267dc0;
	        }
	        border: #267dc0;
	        border-radius: 0;
	        height: 33px;
	        padding: 6px 27px;
	        margin: 0;
	        &:hover{
	            background-color: #2073b2;
	        }
	    }
    }
    .gsc-orderby{
    	white-space: nowrap;
    }
    @include placeholder {
		color: #c2c2c2;
		font-size: 14px;
	}
}
.btn-register{
	color: #FFF;
	background-color: map-get($website_main_color, '3');
	&:hover{
		color: #FFF;
		background-color: #1f73b4;
	}
}