footer{
	
	background-color: #3086c9;
	.footer-box-area{
		@extend %clearfloat;
		padding: 28px 0;
	}
	.footer-box{
		color: #FFF;
		font-size: 15px;
		.footer-info-box{
			+.footer-info-box{
				margin-top: 10px;
			}
		}
		p{
			margin: 0;
		}
		.twca{
			margin-bottom: 15px;
		}
		.copyright{
			margin-top: 15px;
		}
		.acart-area{
			display: block;
			font-size: 15px;
			color: rgba(255, 255, 255, 0.4);
		}
	}
	@media screen and (min-width: $screen_sm_width){
		.footer-box-area{
			display: table;
			width: 100%;
		}
		.footer-box{
			display: table-cell;
			vertical-align: middle;
			&:first-child{
				width: 150px;
			}
			&:nth-child(2){
				padding-left: 60px;
				vertical-align: top;
			}
			&:last-child{
				text-align: right;
				vertical-align: bottom;
				width: 180px;
				padding-right: 8px;

			}
			.twca{
				margin-bottom: 0px;
			}
		}

	}
	@media screen and (min-width: $screen_md_width){
		.footer-box{
			&:nth-child(2){
				padding-top: 8px;
			}
			p{
				
				&:nth-child(2){
					// margin-left: 25px;
				}
				&:nth-child(4){
					// margin-left: 25px;
				}
			}
			.twca{
				margin: 0;
			}
			.copyright{
				margin-top: 10px;
			}

		}

	}

	@media screen and (min-width: $screen_lg_width){
		.footer-box{
			p{
				display: inline-block;
				+p{
					margin-left: 25px;
				}
				&:nth-child(4){
					// margin-left: 0px;
				}
			}
			.acart-area{
				padding-bottom: 6px;
			}
		}
	}
}