.is-sticky{
	header{
		box-shadow: 0 1px 5px rgba(72, 72, 72, .1);
		// z-index: 2;
		// .lg-header-area{
		// 	padding: 11px 0 0;
		// 	.top-area{
		// 		display: none;
		// 	}
		// 	.down-area{
		// 		margin: 0;
		// 	}
		// 	.logo-area{
		// 		img{
		// 			height: 44px;
		// 		}
		// 	}
		// }
	}
	.header-nav{
		// margin-top: 0;
		// >li{
		// 	>a{
		// 		padding: 11px 0 18px ;
		// 	}
		// }
	}

}
.sticky-wrapper{
	z-index: 999 !important;
    position: relative;
	&:not(.is-sticky){
		height: auto !important;
	}
}
.is-sticky .logo-area{
	// margin-top: 5px;
}
header{
    width: 100%;
	top: 0;
	left: 0;
	box-shadow: 0 1px 5px rgba(72, 72, 72, .1);
	background: rgba(255,255,255,1);
	position: relative;
	z-index: 99;
	@media screen and (min-width: $screen_md_width){
		box-shadow: none;
	}
	h1{
		margin: 0;
	}
	.lg-header-area{
		padding: 12px 0 0;
		@extend %clearfloat;
		position: relative;
		
		.top-area{
			text-align: right;
			.link-btn{
				$link_btn_style_config: (
					'en': (
						'hover_color': map-get($website_sub_color, '7')
					),
					'ch': (
						'hover_color': map-get($website_sub_color, '7')
					),
					'member': (
						'hover_color': map-get($website_sub_color, '8')
					),
					'fb': (
						'hover_color': map-get($website_main_color, '3')
					),
					'contact': (
						'hover_color': map-get($website_main_color, '4')
					),
					'search': ( 
						'hover_color': map-get($website_sub_color, '8')
					)

				);
				display: inline-block;
				position: relative;
				+ .link-btn{
					margin-left: 40px;
				}
				@each $style , $style_config in $link_btn_style_config{
					&.#{$style}{
						&:after{
							position: absolute;
							left: -26px;
							top: 1px;
							content: '';
							display: block;
							width: 22px;
							height: 20px;
							background: url('../img/header/header_icon_#{$style}.png');


						}
						&:hover{
							color: map-get($style_config, 'hover_color');
						}
					}
				}

			}
		}
		.down-area{
			@extend %clearfloat;
			margin-top: -22px;
		}
		.btn-area{
			@extend %clearfloat;
			display: inline-block;
			vertical-align: middle;
			.link-btn{
				float: left;
				font-size: 14px;
			}
		}
		.logo-area{
			float: left;
			img{

			}
		}
	}
}
.header-nav{
	@extend %clearfloat;
	list-style: none;
	float: right;
	padding: 0px;
	margin: 0;
	height: 100%;
	margin-top: 24px;
	.header-nav2{
		display: none;
		padding: 0px;
		list-style: none;
	}
	>li{
		float: left;
		position: relative;
		height: 100%;
		display: table;
		+ li{
			margin-left: 14px;

		}
		>a{
			font-size: 18px;
			position: relative;
			display: table-cell;
			height: 100%;
			width: 100%;
			text-align: center;
			vertical-align: middle;
			color: #3f4e5a;
			padding:20px  0 10px;
		}
		@media screen and (min-width:$screen_lg_width){
			+ li{
				margin-left: 35px;

			}
			>a{
				font-size: 18px;
			}
		}
			
		&:hover{
			> a{
				color: #1970b2;
			}
			
		}
		&.active{
			>a{
				color: #1970b2;
			}
		}
		&:hover{
			.header-nav2{
				display: block;
			}
		}
	}
	.header-nav2{
		
		width: 100%;
		position: absolute;
		top: 55px;
		box-shadow: 0 1px 5px rgba(72, 72, 72, 0.1);
		left: 50%;
		background: #f7fdff;
		width: 200px;

		margin-left: -100px;
		


		>li{
			text-align: center;		
			>a{
				position: relative;
				font-size: 15px;
				padding: 10px 0;
				display:block;
				color: map-get($website_main_color,'2');
			}
			&:hover{
				background: map-get($website_sub_color,'3');
				>a{
					color: #FFFFFF;
					&:after{
						display: block;	
					}
				}
			}
		}

	}
	
}

//mobile header
div.mb-header {
    position: relative;
    z-index: 1000;
    padding-right: 60px;

	.mb-logo-area {
		padding-top: 9px;
		padding-bottom: 7px;
		display: inline-block;
	    padding-left: 10px;
		img{
			vertical-align:middle;
			width: 210px;
			max-width: 100%;
			height: auto;
		}
	}
	.button-area {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
	}

	

	div.xs-menu { 
		display: inline-block;
		height: 100%;
		text-align: center;
		cursor: pointer;
		overflow: hidden;
		background-size: 100%;
		background-position: 5px 50%;
		position: relative;
		padding:0 10px;
		.icon-wrapper {
			display: inline-block;
		    margin: 14px 0 0 0;
		    width: 35px;
		    height: 21px;
		    position: relative;
		    
		  
		    span {
			    display: block;
			    position: absolute;
			    height: 4px;
			    width: 100%;
			    border-radius: 4px;
			    background: map-get($website_main_color,'3');
			    opacity: 1;
			    left: 0;
			    -webkit-transform: rotate(0deg);
			    -ms-transform: rotate(0deg);
			    transform: rotate(0deg);
			    -webkit-transition: 400ms ease-in-out;
			    transition: 400ms ease-in-out;

			    &:nth-child(1) { top: 0; }
			    &:nth-child(2) { top: 13px; }
			    &:nth-child(3) { top: 13px; }
			    &:nth-child(4) { top: 26px; }

			}
		}
		&.active {
			span:nth-child(1) {
				top: 7px;
			    width: 0%;
			    left: 50%;
			}
			span:nth-child(2) {
			    -webkit-transform: rotate(45deg);
			    -ms-transform: rotate(45deg);
			    transform: rotate(45deg);
			}
			span:nth-child(3) {
			    -webkit-transform: rotate(-45deg);
			    -ms-transform: rotate(-45deg);
			    transform: rotate(-45deg);
			}
			span:nth-child(4) {
			    top: 14px;
			    width: 0%;
			    left: 50%;
			}
		}
	}
}